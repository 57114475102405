import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
//import Table, {TableBody, TableCell, TableHead, TableRow} from '@material-ui/core/Table';
//import Paper from '@material-ui/core/Paper';
import {TextField, Typography, Button,Toolbar} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import {FormGroup,FormControl,Checkbox,FormControlLabel } from '@material-ui/core';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
//import Dialog from '@material-ui/core/Dialog';
//import Slide from '@material-ui/core/Slide';
import BannerItem from './banner_item';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';

// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }


class BannerDetail extends Component {
  constructor(props) {
    super(props);
    var obj = {image:'',title:'',desc:'',url:'',urlText:'',titleColor:'',descColor:''};
    this.state = {
      banner: {
        name:'',
        loop: true,
        auto: true,
        speed: 900,
        pause: 5000,
        bannerVN: [obj],
        bannerEN: [obj],
        // bannerKR: [obj]
      },
      error: {
        vn: false,
        en: false,
        // kr: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false,
      source: null,
      oldIndex: null,
      newIndex: null
    }
  }
  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;
    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('banners',params.id, function(res) {
          if (res.status == 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                //console.log(item[property]);
                data[property] = item[property];
              }
            }
            data._id = item._id;
            if(data.auto===undefined) data.auto = true;
            if(data.loop===undefined) data.loop = true;
            if(data.speed===undefined) data.speed = 900;
            if(data.pause===undefined) data.pause = 5000;
            $this.setState({banner: data},function(){
              //console.log($this.state.banner);
            });
          }
        });
      });
    }
  }
  addMoreItem(flag){
    var banner = this.state.banner,
        data = banner['banner'+flag.toUpperCase()],
        l = data.length,
        obj = {image:'',title:'',desc:'',url:'',urlText:'',titleColor:'',descColor:''};
        if(l<5){
          data.push(obj);
          banner['banner'+flag.toUpperCase()] = data;
          this.setState({banner:banner},function(){

          });
        }
  }
  removeItem(flag,index){
    var banner = this.state.banner,
        data = banner['banner'+flag.toUpperCase()],
        l = data.length,
        obj = {image:'',title:'',desc:'',url:''};
        if(l>1){
          data.splice(index,1);
          banner['banner'+flag.toUpperCase()] = data;
          this.setState({banner:banner},function(){

          });
        }
  }
  handleFieldChange(e) {
    var $this = this,
        banner = $this.state.banner;
    banner[e.target.id] = e.target.value;
    $this.setState({banner:banner});
  }
  handleCheckboxChange(e) {
    var $this = this,
    banner = $this.state.banner;
    banner[e.target.value] = !banner[e.target.value];
    $this.setState({banner:banner},function(){
      console.log($this.state.banner);
    });
  }
  handleSaveBannerItem(flag,index,item){
    var $this = this,
        field = 'banner'+flag.toUpperCase(),
        banner = $this.state.banner;

    banner[field][index] = item;
    //console.log(banner);
    this.setState({banner:banner},function(){
      //console.log($this.state.banner);
    });
  }
  handleValidate(e,flag) {
    var $this = this,
        banner = this.state.banner,
        obj = this.state.error;

    banner[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() == '' || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;
    this.setState({banner: banner, error:obj}, () => {


    });
  }
  _save(){
    var $this = this;
    var data = this.state.banner;
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('banner',data, function(res) {
        //console.log(res);
        if (res.status == 'success') {
          $this.context.router.history.push('/banner/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('banner',data, function(res) {
        if (res.status == 'success') {
          $this.context.router.history.push('/banner/edit/'+res._id);
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          this._save();
          break;
        case 'en':
          this._save();
          break;
        // case 'kr':
        //   this._save();
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/banner/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleCopy(){
    var data = this.state.banner;
    data.bannerEN = data.bannerVN;
    //data.bannerKR = data.bannerVN;
    this.setState({
      banner: data
    });
  }
  handleDragStart(e,item,index,language){
    var event = e;
    //event.dataTransfer.setData("text/plain", event.target.innerHTML);
    event.dataTransfer.effectAllowed = "move";
    this.setState({
      source: event.target,
      oldIndex: index
    });

  }
  handleDragOver(e){
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  }
  handleDragEnd(e){
    //console.log(e);
  }
  handleDrop(e,item,index,language){
    //drop
    e.preventDefault();
    e.stopPropagation();
    var $this = this,
        banner = this.state.banner,
        data = banner['banner'+language],
        old = data[$this.state.oldIndex];

    data.splice($this.state.oldIndex,1);
    data.splice(index,0,old);
    banner['banner'+language] = data;
    this.setState({
      banner:banner
    },function(){

    });
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const banners = this.state.banner;

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa Banner</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="secondary" aria-label="copy" onClick={()=>this.handleCopy()}>Copy to [EN]</Button>
            <TextField id="name" label="Tên Banner" className={classes.textField} value={this.state.banner.name} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
              shrink: true
            }}/>
            <FormGroup className={classes.formRowInline}>
              <FormControl className={classes.col3}>
                <FormControlLabel
                  control = {
                  <Checkbox
                    checked={this.state.banner.auto}
                    onChange={(event) => this.handleCheckboxChange(event)}
                    value='auto'
                  />}
                  label='Tự động'/>
              </FormControl>
              <FormControl className={classes.col3}>
                <FormControlLabel
                  control = {
                  <Checkbox
                    checked={this.state.banner.loop}
                    onChange={(event) => this.handleCheckboxChange(event)}
                    value='loop'
                  />}
                  label='Lặp vô hạn'/>
              </FormControl>
              <FormControl className={classes.col3}>
                <TextField id="speed" label="Tốc độ chuyển slide (ms)" className={classes.textField} value={this.state.banner.speed} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
                  shrink: true
                }}/>
              </FormControl>
              <FormControl className={classes.col3}>
                <TextField id="pause" label="Thời gian dừng 1 slide" className={classes.textField} value={this.state.banner.pause} onChange={(event) => this.handleFieldChange(event)} margin="normal" InputLabelProps={{
                  shrink: true
                }}/>
              </FormControl>
            </FormGroup>
            <Button variant="contained" color="primary" aria-label="add" size="small" onClick={()=>this.addMoreItem('vn')}>
              Thêm (Slide)
              <AddIcon className={classes.rightIcon}/>
            </Button>
            {
              banners.bannerVN.map((x,i)=> {
                return (
                  <div key={i} className={classNames('banner-item-wrapper',classes.drag_item)} draggable={true} onDragStart={(e)=>this.handleDragStart(e,x,i,'VN')} onDragOver={(e)=>this.handleDragOver(e)} onDragEnd={(e)=>this.handleDragEnd(e)} onDrop={(e)=>this.handleDrop(e,x,i,'VN')}>
                    <IconButton color="secondary" aria-label="delete" size="small" onClick={()=>this.removeItem('vn',i)}>
                      <DeleteIcon />
                    </IconButton>
                    <BannerItem item={this.state.banner.bannerVN[i]} onChangeValue={(banner)=>this.handleSaveBannerItem('vn',i,banner)}/>
                  </div>
                )
              })
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="primary" aria-label="add" size="small" onClick={()=>this.addMoreItem('en')}>
            Thêm (SLIDE) <AddIcon />
            </Button>
            {
              banners.bannerEN.map((x,i)=> {
                return (
                  <div key={i} className={classNames('banner-item-wrapper',classes.drag_item)} draggable={true} onDragStart={(e)=>this.handleDragStart(e,x,i,'EN')} onDragOver={(e)=>this.handleDragOver(e)} onDragEnd={(e)=>this.handleDragEnd(e)} onDrop={(e)=>this.handleDrop(e,x,i,'EN')}>
                    <IconButton color="secondary" aria-label="delete" size="small" onClick={()=>this.removeItem('en',i)}>
                      <DeleteIcon />
                    </IconButton>
                    <BannerItem item={this.state.banner.bannerEN[i]} onChangeValue={(banner)=>this.handleSaveBannerItem('en',i,banner)}/>
                  </div>
                )
              })
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <Button variant="contained" color="primary" aria-label="add" size="small" onClick={()=>this.addMoreItem('kr')}>
            Thêm <AddIcon />
            </Button>
            {
              banners.bannerKR.map((x,i)=> {
                return (
                  <div key={i} className="banner-item-wrapper">
                    <IconButton color="secondary" aria-label="delete" size="small" onClick={()=>this.removeItem('kr',i)}>
                      <DeleteIcon />
                    </IconButton>
                    <BannerItem item={this.state.banner.bannerKR[i]} onChangeValue={(banner)=>this.handleSaveBannerItem('kr',i,banner)}/>
                  </div>
                )
              })
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
      </div>
    )
  }
}

BannerDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
BannerDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(BannerDetail);
