import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {TextField, Button,FormGroup} from '@material-ui/core';
import { SketchPicker } from 'react-color';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import {styles} from '../../layout/theme';
import language from '../../layout/language';
import Utils from '../../api/api';

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: '#000'
    }
  }
  componentDidMount(){
    this.setState({
      value: this.props.value||''
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({
      value: nextProps.value||''
    });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleColorTitleChange = (value) => {
    var color = Utils.convertJsonToColorString(value);

    this.setState({value:color});
    this.props.colorChange(color);
  }
  render(){
    const classes = this.props.classes;
    const value = this.state.value;
    //const open = this.state.open;
    var label = this.props.label;
    if(!label) label = language.VN.image;
    return (
      <div>
        <TextField InputProps={{style:{color:value}}} label={label} className={classes.textField} value={value} onClick={this.handleClickOpen} margin="normal" InputLabelProps={{shrink: true}}/>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}>
          <div className={classes.dialogColor}>
            <FormGroup row className={classes.formRow}>
              <Button variant="contained" color="primary" onClick={this.handleClose}>OK</Button>
            </FormGroup>
            <SketchPicker color={value} className={classes.colorPicker} onChangeComplete={this.handleColorTitleChange}></SketchPicker>
          </div>
        </Dialog>
      </div>
    );
  }
}
ColorPicker.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(ColorPicker);
