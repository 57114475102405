import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import ReactTable from './shares/react_table';
import ReactLazyCardMedia from './shares/react_lazy_card';


import classNames from 'classnames';

import theme,{styles} from '../layout/theme';
import Utils from '../api/api';

export class BannerList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false
    }
  }
  handleSearch(e){
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd(){
    this.context.router.history.push('/banner/add');
  }
  render(){
    const classes = this.props.classes;

    var source = {
      thead: [{title:'Banner',value:'name'},{ title: 'Hình', value: 'photo' }, { title: 'Slide(s)', value: 'slides' }, { title: '', value: 'command' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          name: n.name,
          // title: n.titleVN,
          // desc: n.descVN,
          // url: <div>{n._id}<br /><br />{n.url}</div>,
          slides: n.bannerVN.length,
          photo: <div className={classes.thumbnailWrapper}><ReactLazyCardMedia className={classes.thumbnail} image={n.bannerVN[0] !== undefined ? Utils.fixImagePath(n.bannerVN[0].image) : Utils.fixImagePath('/data/upload/images/no_photo.png')} title={n.bannerVN[0].title} /></div>
        })
      }
    };

    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục banner</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value})}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='banners' apiSingle='banner' apiTable='banners' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

BannerList.propTypes = {
  classes: PropTypes.object.isRequired,
};
BannerList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(BannerList);
