import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Button, Select, FormGroup,FormControl} from '@material-ui/core';
// import Dialog from '@material-ui/core/Dialog';
// import Slide from '@material-ui/core/Slide';
// import Media from './media';
// import language from '../layout/language';
import ColorPicker from './shares/react_color_control';
import ImageControl from './shares/react_image_control';

import {styles} from '../layout/theme';

// function Transition(props) {
//   return <Slide direction="up" {...props} />;
// }

class BannerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url:'',
      urlText:'',
      title: '',
      titleColor:'',
      desc: '',
      descColor:'',
      image:'',
      tab: 0,
      open: false,
      align:'left'
    }
  }
  componentDidMount() {
    var obj = this.props.item;
    this.setState({
      url: obj.url,
      image: obj.image,
      title: obj.title,
      desc: obj.desc,
      urlText: obj.urlText,
      titleColor: obj.titleColor,
      descColor: obj.descColor,
      align:obj.align
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    
    var obj = nextProps.item;
    
    if (obj !== undefined) {
      this.setState({
        url: obj.url,
        image: obj.image,
        title: obj.title,
        desc: obj.desc,
        urlText: obj.urlText,
        titleColor: obj.titleColor,
        descColor: obj.descColor,
        align:obj.align
      });
    }
  }
  selectImg(value){
    var $this = this;
    this.setState({image:value},function(){
      var banner = $this.state;
      $this.props.onChangeValue(banner);
      $this.handleClose();
    });
  }
  // handleSelect = () => {

  // }
  handleFieldChange = (e) => {
    var $this = this,
        banner = this.state;
    banner[e.target.id] = e.target.value;
    
    this.setState(banner,function(){
      //console.log($this.state)
      $this.props.onChangeValue(banner);
    });
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleCover = (value) => {
    var $this = this,
        banner = this.state;
    banner.image = value;
    
    this.setState(banner,function(){
      $this.props.onChangeValue(banner);
    });
  }
  handleColorChange(color,field){
    var $this = this,
        banner = this.state;
    banner[field] = color;
    this.setState(banner,function(){
      $this.props.onChangeValue(banner);
    });
  }
  render() {
    const classes = this.props.classes;
    return (
      <div className="banner-item">
        {/* <TextField id="image" label="Hình" className={classes.textField} value={this.state.image} onClick={this.handleClickOpen} onChange={this.handleFieldChange} margin="normal" InputLabelProps={{
          shrink: true
        }}/> */}
        <ImageControl label="Hình" value={this.state.image} handleChange={this.handleCover}></ImageControl>
        <FormGroup className={classes.formRow}>
          <FormControl>
            <Select native id="align" value={this.state.align} onChange={this.handleFieldChange}>
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </Select>
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRowInline}>
          <FormControl className={classes.col6}>
            <TextField multiline id="title" label="Tiêu đề" className={classes.textField} value={this.state.title} onChange={this.handleFieldChange} margin="normal" InputLabelProps={{shrink: true}}/>
          </FormControl>
          <FormControl className={classes.col6}>
            <ColorPicker className={classes.textField} colorChange={(color)=>this.handleColorChange(color,'titleColor')} label="Màu chữ" value={this.state.titleColor}></ColorPicker>
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRowInline}>
          <FormControl className={classes.col6}>
            <TextField id="desc" label="Chú thích" multiline className={classes.textField} value={this.state.desc} onChange={this.handleFieldChange} margin="normal" InputLabelProps={{shrink: true}}/>
          </FormControl>
          <FormControl className={classes.col6}>
            <ColorPicker colorChange={(color)=>this.handleColorChange(color,'descColor')} label="Màu chữ" value={this.state.descColor}></ColorPicker>
          </FormControl>
        </FormGroup>
        <FormGroup className={classes.formRowInline}>
          <FormControl className={classes.col6}>
            <TextField id="urlText" label="Nhãn liên kết" className={classes.textField} value={this.state.urlText} onChange={this.handleFieldChange} margin="normal" InputLabelProps={{shrink: true}}/>
          </FormControl>
          <FormControl className={classes.col6}>
            <TextField id="url" label="Liên kết" className={classes.textField} value={this.state.url} onChange={this.handleFieldChange} margin="normal" InputLabelProps={{shrink: true}}/>
          </FormControl>
        </FormGroup>
        {/* <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <div className={classes.dialogMedia}>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={this.handleClose}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={this.handleSelect}>{language.VN.btnSave}</Button>
            </FormGroup>
            <Media onSelectChange={(value)=>this.selectImg(value)}/>
          </div>
        </Dialog> */}
      </div>
    )
  }
}

BannerItem.propTypes = {
  classes: PropTypes.object.isRequired
};
BannerItem.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(BannerItem);
